import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  // openNav() {
  //   const smenu = document.getElementById('sidemenu');
  //   if (smenu != null) {
  //     smenu.style.display = 'inline-table';
  //   }
  // }

  closeNav() {
    const smenu = document.getElementById('sidemenu');
    if (smenu != null) {
      smenu.style.display = 'none';
    }
  }
}
