import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    //@ts-ignore
    if (!!window.ReactNativeWebView)
      // app的webview模式專用關閉網址
      window.location.replace('https://cooc.tp.edu.tw/app/close');
    else
      // 登出後回酷課雲首頁
      window.location.replace('/service/logout?next=' + encodeURIComponent("https://cooc.tp.edu.tw/"));
  }

}
