<div class="modal-content">
  <div class="modal-header"
       *ngIf="title">
    <h5 class="modal-title">{{title}}</h5>
    <button type="button"
            class="close"
            (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    {{message}}
  </div>
  <div class="modal-footer">
    <button type="button"
            class="btn btn-secondary btn-sm"
            (click)="close()">確定</button>
  </div>
</div>