import { Component, OnInit } from '@angular/core';
import { ParentService } from './core/parent.service';
import { TeacherService } from './core/teacher.service';
import { ClassRec, TeacherRec } from './data/data';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = '班級聯絡簿';

  constructor(
  ) {}

  ngOnInit() {
  }
}
