import { Component, OnInit, Input, Inject } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';


@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent implements OnInit {

  @Input() title: string = "";
  @Input() message: string = "";

  constructor(
    public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  close() {
    this.bsModalRef.hide();
  }

}
