<div class="comenu" id="sidemenu" (click)="closeNav()">
    <div class="lmenu">
        <div>
            <img class="closeMenu" src="prototype/img/close.svg" alt="" (click)="closeNav()">
        </div>
        <div style="padding: 28px 24px 12px;">
            <span>首頁</span>
            <span class="material-icons" style="vertical-align: text-top;">chevron_right</span>
            <span class="headactive">班級聯絡簿</span>
        </div>
        <div class="dshr8"></div>

        <div class="accordion" id="accMenu">
            <button id="sett" class="btntt d-flex align-items-center justify-content-between w-100" type="button"
                data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                <div class="material-icons-outlined mr-2">settings</div>管理
            </button>

            <div id="collapseOne" class="collapse show" aria-labelledby="sett" data-parent="#accMenu">
                <button class="dropdown-item active" [routerLink]="['create-book']">建立今日聯絡簿</button>
                <button class="dropdown-item">行事曆</button>
                <button class="dropdown-item">搜尋</button>
                <button class="dropdown-item">權限設定</button>
            </div>
        </div>

        <div class="dshrs"></div>
        <button class="btntt d-flex align-items-center w-100">
            <span class="material-icons-outlined mr-2">help_outline</span>Q＆A
        </button>
        <div class="dshrs"></div>


    </div>
</div>
